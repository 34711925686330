import React, { Fragment } from "react"
import { Link, graphql } from "gatsby"
import { motion } from "framer-motion"
import Header from "../components/header"
import Container from "../components/container"

const easing = [0.6, -0.5, 0.01, 0.99]

const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
}

const stagger = {
  animate: {
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const Blog = ({ data }) => {
  // const siteTitle = data.site.siteMetadata.title
  // const siteDescription = data.site.siteMetadata.description
  const posts = data.allMdx.nodes
  console.log(posts)
  return (
    <motion.div exit={{ opacity: 0 }} initial="initial" animate="animate">
      <Header />
      <Container>
        <motion.div variants={stagger} className="prose mt-24">
          <h1>Blog</h1>
          <div className="grid sm:grid-cols-2 gap-4">
            {posts.map((post, i) => {
              console.log(post.frontmatter)
              const title = post.frontmatter.title || post.field.slug
              return (
                <motion.div
                  variants={fadeInUp}
                  key={`post-${i}`}
                  style={{
                    backgroundColor: `${post.frontmatter.featureColor}`,
                  }}
                  className="p-6"
                >
                  <Link to={`${post.slug}`}>
                    <h3>{title}</h3>
                  </Link>
                  <p>{post.frontmatter.date}</p>
                  <p>{post.frontmatter.description}</p>
                </motion.div>
              )
            })}
          </div>
        </motion.div>
      </Container>
    </motion.div>
  )
}

export default Blog

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        slug
        frontmatter {
          date(formatString: "Do MMMM YYYY")
          title
          description
          featureColor
        }
      }
    }
  }
`
